/** After the Confirmation page, users will be
 * redirected to the Activation page to activate
 * their subscription
 * To use:
 * ENDPOINT -> /activate */

import React from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const Activate = () => {
  const navigate = useNavigate();
  const navigateToSubscription = () => {
    navigate("/subscriptions");
  };
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="pt-24 pb-10 xl:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="main-content-label">
                Thank you for choosing{" "}
                <span className="gradient-text-big">VODIUM</span>!
              </p>
            </div>
          </div>
        </div>

        <div className="px-6 mx-auto">
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  <p>We are currently processing your request.</p>
                  <p>
                    The updated status of your purchase is available in the
                    Subscriptions page.
                  </p>

                  <button
                    className="blue button view-button mr-3"
                    onClick={navigateToSubscription}
                  >
                    Go to Subscriptions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Activate;
