import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const WelcomeName = ({viewpage}) => {
    const { instance } = useMsal();
    const [name, setName] = useState(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name.split(' ')[0]);
        } else {
            setName(null);
        }
    }, [activeAccount]);

    if (viewpage==="VODIUM") {
        return <p className='none-gradient'> Welcome to <span className="gradient-text">VODIUM</span>{name ? <span>, {name}</span> : ''}</p>
    } else if (viewpage==="HOME") {
        return <p className="text-base font-bold text-gray-900">Hey{name ? <span> {name}</span> : ''} -</p>
    }
};

export default WelcomeName;