/** This is the landing page where the customer will
 * be redirected after their SaaS purchase from Microsoft
 * To use:
 * ENDPOINT -> /confirmation?token=<token> */

import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import {
  addAppRoleAdmin,
  callMsGraph,
  getUserAppRoles,
} from "../services/msGraphApiCall";
import { loginRequest } from "../services/authProvider";
import { login } from "../services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import {
  activateSubscription,
  resolveSubscription,
} from "../services/subscriptions";
import { connect } from "react-redux";

const Confirmation = (jwt) => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({
    subscriptionId: "",
    offerId: "",
    planId: "",
    subscriptionName: "",
  });
  const [userData, setUserData] = useState({
    userName: "",
    email: "",
  });
  const navigate = useNavigate();
  const navigateToActivate = () => {
    const activateSub = async () => {
      await activateSubscription(
        subscriptionData.subscriptionId,
        subscriptionData.planId
      );
      navigate("/activate");
    };
    activateSub();
  };

  /* Handles the Customer Identification Token generated by Microsoft found in the URL*/
  const location = useLocation();
  const queryParamToken = new URLSearchParams(location.search);
  const customerIdentificationToken = queryParamToken.get("token");
  const decodedMarketplaceToken = decodeURIComponent(
    customerIdentificationToken
  );
  // console.log("decodedToken", decodedMarketplaceToken);
  useEffect(() => {
    const fetchResolveSubscription = async () => {
      const response = await resolveSubscription(decodedMarketplaceToken);
      setSubscriptionData({
        subscriptionId: response.subscription.id,
        offerId: response.offerId,
        planId: response.planId,
        subscriptionName: response.subscriptionName,
      });
    };
    fetchResolveSubscription();
  }, decodedMarketplaceToken);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  useEffect(() => {
    const account = instance.getActiveAccount();

    if (!account && inProgress === InteractionStatus.None) {
      login();
    }
    setUserData({
      userName: account.name,
      email: account.username,
    });

    /*assignAdmin - assingns the logged in user an admin role */
    // const assignAdmin = async () => {
    //   await addAppRoleAdmin(null, graphData.id) //create new endpoint for new/higher role
    //     .then(() => {
    //       getUserAppRoles(null, graphData.id).then((response1) => {
    //         console.log("after delete and adding", response1);
    //       });
    //     });
    // };

    // if (account && graphData != null) {
    //   console.log("if enter");
    //   assignAdmin();
    // }
  }, [graphData, inProgress, instance]);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="pt-24 pb-10 xl:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="main-content-label">Subscriptions</p>
            </div>
          </div>
        </div>

        <div className="px-6 mx-auto">
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  {!customerIdentificationToken && (
                    <div className="flex items-center justify-center">
                      You currently don't have a subscription. Purchase an offer
                      here.
                    </div>
                  )}

                  {customerIdentificationToken && (
                    <>
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Configure your VODIUM subscription here.
                      </p>
                      <div className="mt-3">
                        <p>User: {userData.userName}</p>
                        <p>Email: {userData.email}</p>
                        <p>Offer ID: {subscriptionData.offerId}</p>
                        <p>Plan ID: {subscriptionData.planId}</p>
                        <p>
                          Subscription Name: {subscriptionData.subscriptionName}
                        </p>
                      </div>

                      <button
                        className="blue button view-button mr-3"
                        onClick={navigateToActivate}
                      >
                        Activate Subscription
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    jwt: state.app.accessToken,
  };
};

export default connect(mapStateToProps)(Confirmation);
